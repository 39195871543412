@import 'variables';
@import 'functions';
@import 'fonts';
@import 'stacking-orders';
@import 'tooltip';

* {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

a {
	color: inherit;
}

html {
	font-size: $root-font-size;
	height: 100%;
}

body {
	font-family: $font-family;
	@include set-typography('xs');
	display: flex;
	width: 100%;
	min-height: 100%;
	color: $color-black;
	background: var(--color-background);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
}

.beamer_show .beamer_beamer.left {
	left: $width-sidebar !important;
	@media (max-width: 800px) {
		width: calc(100% - $width-sidebar) !important;
	}
}

.beamer_noscroll {
	overflow: visible !important;
}

.side-bar-collapsed .beamer_show .beamer_beamer.left {
	left: $width-sidebar--collapsed !important;
	@media (max-width: 800px) {
		width: calc(100% - $width-sidebar--collapsed) !important;
	}
}

.oper-link {
	color: var(--color-primary);
	cursor: pointer;

	&__underline {
		text-decoration: underline;
	}
}

.highlighted {
	color: var(--color-primary);
	font-style: normal;
}
