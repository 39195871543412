// Dependencies

// Base Colors
$color-primary: var(--color-primary);
$color-secondary: var(--color-secondary);
$color-white: #fff;
$color-offwhite: #f5f5f5;
$color-shade: #ccc;
$color-shade-light: #e5e5e5;
$color-shade-dark: #666;
$color-text-primary: #222;
$color-link-primary: $color-primary;

$color-black: black;
$color-black-95: rgba(0, 0, 0, 0.95);
$color-black-90: rgba(0, 0, 0, 0.9);
$color-black-85: rgba(0, 0, 0, 0.85);
$color-black-80: rgba(0, 0, 0, 0.8);
$color-black-75: rgba(0, 0, 0, 0.75);
$color-black-70: rgba(0, 0, 0, 0.7);
$color-black-65: rgba(0, 0, 0, 0.65);
$color-black-60: rgba(0, 0, 0, 0.6);
$color-black-55: rgba(0, 0, 0, 0.55);
$color-black-50: rgba(0, 0, 0, 0.5);
$color-black-45: rgba(0, 0, 0, 0.45);
$color-black-40: rgba(0, 0, 0, 0.4);
$color-black-35: rgba(0, 0, 0, 0.35);
$color-black-30: rgba(0, 0, 0, 0.3);
$color-black-25: rgba(0, 0, 0, 0.25);
$color-black-20: rgba(0, 0, 0, 0.2);
$color-black-15: rgba(0, 0, 0, 0.15);
$color-black-10: rgba(0, 0, 0, 0.1);
$color-black-05: rgba(0, 0, 0, 0.05);

$color-blue-100: #dbeafe;
$color-blue-500: #3b82f6;

$color-white: white;
$color-white-95: rgba(255, 255, 255, 0.95);
$color-white-90: rgba(255, 255, 255, 0.9);
$color-white-85: rgba(255, 255, 255, 0.85);
$color-white-80: rgba(255, 255, 255, 0.8);
$color-white-75: rgba(255, 255, 255, 0.75);
$color-white-70: rgba(255, 255, 255, 0.7);
$color-white-65: rgba(255, 255, 255, 0.65);
$color-white-60: rgba(255, 255, 255, 0.6);
$color-white-55: rgba(255, 255, 255, 0.55);
$color-white-50: rgba(255, 255, 255, 0.5);
$color-white-45: rgba(255, 255, 255, 0.45);
$color-white-40: rgba(255, 255, 255, 0.4);
$color-white-35: rgba(255, 255, 255, 0.35);
$color-white-30: rgba(255, 255, 255, 0.3);
$color-white-25: rgba(255, 255, 255, 0.25);
$color-white-20: rgba(255, 255, 255, 0.2);
$color-white-15: rgba(255, 255, 255, 0.15);
$color-white-10: rgba(255, 255, 255, 0.1);
$color-white-05: rgba(255, 255, 255, 0.05);

$color-gray-100: #f4f4f4;
$color-gray-200: #dcdcdc;
$color-gray-300: #cacaca;
$color-gray-400: #959595;
$color-gray-500: #4f4f4f;
$color-gray-600: #3f3f3f;
$color-gray-700: #2f2f2f;
$color-gray-800: #0d0d0e;

$color-grey-icon: #4a5568;
$color-grey: #4f4f4f;
$color-grey-light: #a5a5a5;

$color-info: #2d3775;
$color-info-light: #eef0f6;
$color-success: #008033;
$color-success-100: #ebf6ef;
$color-success-light: #e6f2eb;
$color-warning: #de6102;
$color-warning-light: #ffe177;
$color-warning-100: #fcf4da;
$color-warning-500: #f2ca44;
$color-danger: #c00;
$color-danger-light: #fae6e6;
$color-success-800: #287342;
$color-success-500: #39a55e;
$color-success-200: #9cd2af;
$color-success-100: #ebf6ef;
$color-purple-800: #343e84;
$color-purple-200: #abb3d0;
$color-purple-100: #edeef8;
$color-error-500: #df0016;

// Document status colors
$color-requested: rgba(224, 224, 224, 0.4);
$color-to-be-signed: rgba(224, 224, 224, 0.4);
$color-informative: #82b2c0;
$color-to-be-reviewed: #82b2c0;
$color-verified: #a5d6a7;
$color-rejected: #ef9a9a;

// Sizes
$size-border-radius-extreme-large: 3.2rem;
$size-border-radius-large: 1.6rem;
$size-border-radius: 0.8rem;
$size-border-radius-small: 0.4rem;
$size-spacing: 0.4rem;
$size-icon-small: 10;
$size-icon-medium: 15;
$size-icon-large: 20;
$border-default: 0.1rem solid $color-gray-200;
$border-hover: 0.1rem solid $color-gray-400;
$border-focus: 0.1rem solid $color-primary;
$border-error: 0.1rem solid $color-error-500;
$border-disabled: 0.1rem solid $color-gray-200;

$border-light: 1px solid $color-black-10;

// Widths
$width-content-max: 1800px;
$width-content-xlarge: 1200px;
$width-content-large: 992px;
$width-content-medium: 768px;
$width-content-medium-centered: 600px;
$width-content-small: 576px;
$width-content-tiny: 420px;
$width-content-micro: 320px;
$width-sidebar: 20rem !default;
$width-sidebar--collapsed: 56px;
$profile-image-rounded: 100px;

// Speeds
$speed-transition-fast: 200ms;
$speed-transition-normal: 450ms;
$speed-transition-slow: 750ms;

$gradient-arrow-icon-uri: 'data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJjaGV2cm9uLWRvd24iIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaGV2cm9uLWRvd24gZmEtdy0xNCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik00NDEuOSAxNjcuM2wtMTkuOC0xOS44Yy00LjctNC43LTEyLjMtNC43LTE3IDBMMjI0IDMyOC4yIDQyLjkgMTQ3LjVjLTQuNy00LjctMTIuMy00LjctMTcgMEw2LjEgMTY3LjNjLTQuNyA0LjctNC43IDEyLjMgMCAxN2wyMDkuNCAyMDkuNGM0LjcgNC43IDEyLjMgNC43IDE3IDBsMjA5LjQtMjA5LjRjNC43LTQuNyA0LjctMTIuMyAwLTE3eiI+PC9wYXRoPjwvc3ZnPg==';
$three-dots-loader: var(--three-dots-loader);
$three-dots-loader-white: var(--three-dots-loader-white);

// Shadows
$box-shadow-default: 0 20px 20px rgba(0, 0, 0, 0.05);
$shadow-blur: 2px;
$shadow-blur-extra: 12px;
$box-shadow-faint: 0 1px $shadow-blur rgba(0, 0, 0, 0.075);
$box-shadow-faint--up: 0 -1px $shadow-blur rgba(0, 0, 0, 0.075);
$box-shadow-extra: 0 4px $shadow-blur-extra rgba(0, 0, 0, 0.2);
$box-shadow-card: 0px 4px 10px 0px #0000001a;
$box-shadow-context-menu: 0px 4px 23px 0px rgba(0, 0, 0, 0.08);

// Easings
$easing-InOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
