/** Dependencies **/
@import '~normalize.css/normalize.css';
@import "material-theme";

/** Core **/
@import 'core';
@import 'functions';

@tailwind utilities;

/** Tailwind CSS responsive variants of classes **/
@layer utilities {
	.span0_5 {
		width: calc(100% * (0.5 / 12));
	}

	.span0_75 {
		width: calc(100% * (0.75 / 12));
	}

	.span1 {
		width: calc(100% * (1 / 12));
	}

	.span1_25 {
		width: calc(100% * (1.25 / 12));
	}

	.span1_5 {
		width: calc(100% * (1.5 / 12));
	}

	.span2 {
		width: calc(100% * (2 / 12));
	}

	.span2_25 {
		width: calc(100% * (2.25 / 12));
	}

	.span3 {
		width: calc(100% * (3 / 12));
	}

	.span4 {
		width: calc(100% * (4 / 12));
	}

	.min-span4 {
		min-width: calc(100% * (4 / 12));
	}

	.span5 {
		width: calc(100% * (5 / 12));
	}

	.span6 {
		width: calc(100% * (6 / 12));
	}

	.min-span6 {
		min-width: calc(100% * (6 / 12));
	}

	.span7 {
		width: calc(100% * (7 / 12));
	}

	.span8 {
		width: calc(100% * (8 / 12));
	}

	.span9 {
		width: calc(100% * (9 / 12));
	}

	.span10 {
		width: calc(100% * (10 / 12));
	}

	.span11 {
		width: calc(100% * (11 / 12));
	}

	.span12 {
		width: calc(100% * (12 / 12));
	}

	.top-margin-spacing8 {
		margin-top: spacing(8);
	}
}
